<template>
  <span class="inline-block whitespace-break-spaces">
    <UModal
      v-model="isOpen"
      :ui="{
        width: 'max-w-[900px] w-auto',
        // inner: 'fixed inset-0 overflow-y-auto',
        padding: 'p-2',
        base: 'relative text-left text-20 p-6',
        background: 'bg-white bg-[url(/img/page-bg.svg)]',
      }"
    >
      <ModalCloseButton
        class="-mt-6 -mr-6 rounded-tr overflow-hidden"
        @click="isOpen = false"
      />

      <slot>
        <LayoutText class="!m-0">{{ text }}</LayoutText>
      </slot>
    </UModal>
    <LayoutTextButton @click="openModal"><slot name="button">{{ btnLabel }}</slot></LayoutTextButton>
  </span>
</template>

<script lang="ts" setup>
const isOpen = ref(false)

const props = defineProps({
  externalIsOpen: {
    default: false,
    type: Boolean,
    required: false,
  },
  text: {
    type: String,
    default: undefined,
    required: false,
  },
  btnLabel: {
    type: String,
    default: undefined,
    required: true,
  },
})

const emit = defineEmits(['modalOpen'])

function openModal() {
  isOpen.value = true
  emit('modalOpen')
}

watch(
  () => props.externalIsOpen,
  () => {
    isOpen.value = props.externalIsOpen
  },
)
</script>
